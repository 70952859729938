import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/720p_Series_CGI_List/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "720p CGI Commands",
  "path": "/720p_Series_CGI_List/System_Menu/IR_LEDs/",
  "dateChanged": "2018-03-26",
  "author": "Mike Polinowski",
  "excerpt": "Camera API control for developers",
  "image": "../../AU_SearchThumb_CGICommands_720p.png",
  "social": "/images/Search/AU_SearchThumb_CGICommands_720p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_CGIs_white.webp",
  "chapter": "720p CGI Commands"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='720p CGI Commands' dateChanged='2018-03-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Camera API control for developers' image='/images/Search/AU_SearchThumb_CGICommands_720p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_720p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/720p_Serie_CGI_Befehle/System_Menu/IR_LEDs/' locationFR='/fr/720p_Series_CGI_List/System_Menu/IR_LEDs/' crumbLabel="720p CGI Commands" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "the-system-menu--ir-leds",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-system-menu--ir-leds",
        "aria-label": "the system menu  ir leds permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The System Menu :: IR LEDs`}</h2>
    <br /><br />
    <hr></hr>
    <h3 {...{
      "id": "paramcgicmdsetinfrared",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#paramcgicmdsetinfrared",
        "aria-label": "paramcgicmdsetinfrared permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=setinfrared`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Function`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Set IR Light Parameter`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Authority`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`admin`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Return`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`infraredstat`}</code>{`: IR Status - auto, open and close`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Example`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=getinfrared`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=setinfrared&-infraredstat=auto`}</code></td>
        </tr>
      </tbody>
    </table>
    <br /><br />
    <h3 {...{
      "id": "paramcgicmdsetplancgi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#paramcgicmdsetplancgi",
        "aria-label": "paramcgicmdsetplancgi permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=setplancgi`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Function`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Define a Time Window to Switch IR LEDs from close to auto`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Authority`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`admin`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Return`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`plancgi_enable_0`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`plancgi_enable_1`}</code>{`: `}{`[0,1]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`plancgi_time_0`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`plancgi_time_1`}</code>{`: `}{`[0 - 86399]`}{` in seconds = 0:00:00 - 23:59:59 o´clock;`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Example`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=getplancgi`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=setplancgi&-plancgi_enable_0=0&-plancgi_enable_1=0`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=setplancgi&-plancgi_enable_0=1&-plancgi_enable_1=1&-plancgi_time_0=21600&-plancgi_time_1=64800`}</code></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      